import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b021ef3c = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _011a3964 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _2f8bb916 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _82c08242 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _06b02878 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _64fdded1 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _db3b4648 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _d7f64dd8 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _b3435f2c = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _a14117de = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _797c0943 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _e6ef0bd6 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _3ec54d07 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _0c60f19e = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _47c1d5e1 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _c37cc2cc = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _3eba77b9 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _2a1c778c = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _1e2b0fb8 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/lojas",
    component: _b021ef3c,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _011a3964,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _2f8bb916,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _82c08242,
    name: "motos"
  }, {
    path: "/reset",
    component: _06b02878,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _64fdded1,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _db3b4648,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _d7f64dd8,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _b3435f2c,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _a14117de,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _797c0943,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _e6ef0bd6,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _3ec54d07,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _0c60f19e,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _47c1d5e1,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _c37cc2cc,
    name: "produto-id-all"
  }, {
    path: "/lista/*",
    component: _3eba77b9,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _2a1c778c,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _1e2b0fb8,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
